"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var $header = document.querySelector('.header');
var $stickyMain = document.querySelector('[data-type=ts-sticky-nav-type-main]');
var $stickySub = document.querySelector('[data-type=ts-sticky-nav-type-sub]');
var $stickySubInner = document.querySelector('[data-type=ts-sticky-nav-type-sub] > div');
var isStickyMainActive = false;
var isStickySubActive = false;
var stickyNav = function () {
    if (!$header || !$stickyMain)
        return;
    checkSticky();
    window.addEventListener('scroll', function () { return checkSticky(); });
};
var checkSticky = function () {
    //sticky main
    initStickyMain();
    //sticky sub
    initStickySub();
};
var initStickyMain = function () {
    if (!$header || !$stickyMain)
        return;
    if (window.innerWidth < 768) {
        isStickyMainActive && undoStickyMain();
    }
    else {
        window.scrollY > $header.offsetHeight ? doStickyMain() : undoStickyMain();
    }
};
var initStickySub = function () {
    if (!$stickySub || !$stickySubInner)
        return;
    $stickySub.getBoundingClientRect().top <= $stickyMain.offsetHeight ? doStickySub() : undoStickySub();
};
var doStickyMain = function () {
    $stickyMain.classList.add('sticky-top');
    isStickyMainActive = true;
};
var undoStickyMain = function () {
    $stickyMain.classList.remove('sticky-top');
    isStickyMainActive = false;
};
var doStickySub = function () {
    $stickySub.classList.add('sticky-top');
    $stickySubInner.style.top = "".concat($stickyMain.offsetHeight, "px");
    $stickyMain.classList.add('sticky-sub-active');
    isStickySubActive = true;
};
var undoStickySub = function () {
    $stickySub.classList.remove('sticky-top');
    $stickySubInner.style.top = "auto";
    $stickyMain.classList.remove('sticky-sub-active');
    isStickySubActive = false;
};
exports.default = stickyNav;
