const axios = require('axios');
const triggers = document.querySelectorAll('.user-offer');
const spinner = '<span class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>';

const showError = ({message, trigger, triggerType, showResultAs}) => {
	if (!showResultAs) {
		triggerType === 'link' ? trigger.classList.add('text-danger') : trigger.classList.add('btn-danger');
		trigger.innerHTML = message;
	}
	if (showResultAs === 'text') {
		const el = document.createElement('span');
		el.innerHTML = message;
		el.classList.add('text-danger');
		trigger.parentNode.insertBefore(el, trigger.nextSibling);
		trigger.parentNode.removeChild(trigger);
	}
};

const showOK = ({message, trigger, triggerType, showResultAs}) => {

	if (!showResultAs) {
		triggerType === 'link' && trigger.classList.add('text-success');
		trigger.innerHTML = message;
	}
	if (showResultAs === 'text') {
		const el = document.createElement('span');
		el.innerHTML = message;
		el.classList.add('text-success');
		trigger.parentNode.insertBefore(el, trigger.nextSibling);
		trigger.parentNode.removeChild(trigger);
	}
}

const userOffer = () => {

	triggers.forEach((trigger) => {

		trigger.addEventListener('click', (e) => {

			e.preventDefault();

			const url = trigger.getAttribute('href');
			const waitMessage = trigger.getAttribute('data-wait') ? trigger.getAttribute('data-wait') : 'Bitte warten...';
			const reloadAfterSuccess = trigger.getAttribute('data-reload-after-success');
			const triggerType = trigger.classList.contains('btn') ? 'button' : 'link';
			const showResultAs = trigger.getAttribute('data-show-result-as');

			trigger.classList.add('disabled', 'position-relative');
			trigger.innerHTML = waitMessage + spinner;

			const options = {
				url: url,
				//headers: {'X-Requested-With': 'XMLHttpRequest'}
			};
		
			axios(options)
			.then((result) => {

				const obj = {
					trigger: trigger,
					triggerType: triggerType,
					message: result.data.message,
					showResultAs: showResultAs
				}
				
				if (result.status != 200 || result.data.error) {
					showError(obj);
				} else {
					reloadAfterSuccess ? window.location.reload(false) : showOK(obj)
				}

			})
			.catch(() => {
				const obj = {
					trigger: trigger,
					triggerType: triggerType,
					message: trigger.getAttribute('data-error') ? trigger.getAttribute('data-error') : 'Ein Fehler ist aufgetreten.',
					showResultAs: showResultAs
				}
				showError(obj);
			});

		});
	});

};

export default userOffer;
