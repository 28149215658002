"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var throttle = function (callback, delay) {
    var wait = false;
    return function () {
        if (!wait) {
            callback();
            wait = true;
            setTimeout(function () {
                wait = false;
            }, delay);
        }
    };
};
exports.default = throttle;
