"use strict";
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var triggerBox = document.getElementById('ts-load-more');
var results = document.getElementById('ts-load-more-results');
var total = parseInt((_a = triggerBox === null || triggerBox === void 0 ? void 0 : triggerBox.getAttribute('data-load-more-total')) !== null && _a !== void 0 ? _a : '0');
var steps = parseInt((_b = triggerBox === null || triggerBox === void 0 ? void 0 : triggerBox.getAttribute('data-load-more-steps')) !== null && _b !== void 0 ? _b : '10');
var params = (_c = triggerBox === null || triggerBox === void 0 ? void 0 : triggerBox.getAttribute('data-load-more-params')) !== null && _c !== void 0 ? _c : '';
var urlParams = (_d = triggerBox === null || triggerBox === void 0 ? void 0 : triggerBox.getAttribute('data-load-more-urlparams')) !== null && _d !== void 0 ? _d : '';
var trigger = document.getElementById('ts-load-more-trigger');
var spinner = document.getElementById('ts-load-more-spinner');
var errorMsg = document.getElementById('ts-load-more-error');
var source = (trigger && trigger.href) || null;
var offset = 0;
var page = 1;
var loadMore = function () {
    init();
};
var getParams = function () {
    var result = {
        offset: offset,
        page: page
    };
    //params from inputs
    params.split(',').map(function (param) { return param.trim(); }).forEach(function (param) {
        if (!param)
            return;
        var element = document.getElementById(param);
        //element is not found
        if (!element) {
            console.error("Parameter error: element with id ".concat(param, " not found"));
            return '';
        }
        if (element.type === 'checkbox') {
            if (element.checked) {
                result[param] = element.value;
            }
            return;
        }
        result[param] = element.value;
    });
    //params from URL
    urlParams.split(',').map(function (param) { return param.trim(); }).forEach(function (param) {
        var url = new URL(window.location.href);
        result[param] = !!url.searchParams.get(param) ? url.searchParams.get(param) : '';
    });
    return result;
};
var increasePagination = function () {
    offset = offset + steps;
};
var decreasePagination = function () {
    offset = offset - steps;
};
var init = function () {
    if (!trigger || !source)
        return;
    trigger.addEventListener('click', function (e) {
        errorMsg.classList.add('d-none');
        spinner.classList.remove('d-none', 'ts-fade-out');
        increasePagination();
        var options = {
            url: source,
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            method: 'GET',
            contentType: 'text/html',
            params: getParams(),
        };
        (0, axios_1.default)(options)
            .then(function (response) {
            if (response.data) {
                spinner.classList.add('ts-fade-out');
                setTimeout(function () {
                    spinner.classList.add('d-none');
                }, 500);
                results.insertAdjacentHTML('beforeend', response.data);
                page = page + 1;
                if (steps * page >= total) {
                    triggerBox.classList.add('ts-fade-out');
                    setTimeout(function () {
                        triggerBox.remove();
                    }, 500);
                }
            }
        })
            .catch(function () {
            spinner.classList.add('d-none');
            errorMsg.classList.remove('d-none');
            decreasePagination();
        });
        e.preventDefault();
    });
};
exports.default = loadMore;
