"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var togglers = document.querySelectorAll('.ts-text-toggler');
var spinner = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
var textToggler = function () {
    togglers.forEach(function (toggler) {
        var trigger = toggler.querySelector('.ts-text-toggler-trigger');
        var textWrap = toggler.querySelector('.ts-text-toggler-text-wrap');
        var text = toggler.querySelector('.ts-text-toggler-text');
        var textWrapClosedHeight = getComputedStyle(textWrap).height;
        var triggerTextClosed = trigger.textContent;
        var triggerTextOpen = trigger.getAttribute('data-textclose') || 'Zuklappen';
        var isOpen = false;
        trigger && trigger.addEventListener('click', function (e) {
            if (isOpen) {
                toggler.classList.add('animating');
                toggler.classList.remove('open');
                slideUp(textWrap, textWrapClosedHeight);
                trigger.innerHTML = spinner;
                setTimeout(function () {
                    trigger.textContent = triggerTextClosed;
                    toggler.classList.remove('animating');
                }, 500);
            }
            else {
                textWrap.style.height = text.offsetHeight + 'px';
                toggler.classList.add('open', 'animating');
                trigger.innerHTML = spinner;
                setTimeout(function () {
                    trigger.textContent = triggerTextOpen;
                    toggler.classList.remove('animating');
                    textWrap.style.height = 'auto';
                }, 500);
            }
            isOpen = !isOpen;
            e.preventDefault();
        });
    });
};
//todo: merge this slideUp and the slideUp in pageBookmark.js to one utility function
var slideUp = function (item, height) {
    var slideUpTo = height || '0px';
    var scrollHeight = item.scrollHeight + 'px';
    var noHeightSet = !item.style.height || item.style.height === 'auto';
    item.style.height = (noHeightSet ? scrollHeight : slideUpTo);
    if (noHeightSet)
        slideUp(item, height);
};
exports.default = textToggler;
