"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var valid_form_1 = require("@pageclip/valid-form");
var formElement = document.querySelector('main form');
var validForm = function () {
    if (formElement) {
        new valid_form_1.default(formElement, {
            invalidClass: 'is-invalid',
            validationErrorClass: 'invalid-feedback',
            validationErrorParentClass: 'was-validated',
        });
    }
};
exports.default = validForm;
