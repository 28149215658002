const cssVariables = () => {

	//set window width
	let timeout;
	setWidth();

	window.addEventListener('resize', function(){
		clearTimeout(timeout);
		timeout = setTimeout(setWidth, 100);	//throttle resize event
	});

};

//needed for e.g. webAdd
const setWidth = () => document.querySelector(':root').style.setProperty('--client-width', document.body.clientWidth + 'px');

export default cssVariables;