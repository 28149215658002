"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var el = document.getElementById('stickySearch');
var triggers = document.querySelectorAll('a.ts-trigger-sticky-search');
var stickySearch = function () {
    triggers.forEach(function (trigger) {
        trigger.addEventListener('click', function (e) {
            show();
            e.preventDefault();
        });
    });
    el.querySelector('.ts-close').addEventListener('click', function (e) {
        hide();
        e.preventDefault();
    });
};
var show = function () {
    el.classList.add('show');
    el.querySelector('[name=ts-navbar-searchform-input]').focus();
};
var hide = function () {
    el.classList.remove('show');
};
exports.default = stickySearch;
