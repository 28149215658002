"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var initializer = document.querySelectorAll('[data-plugin=ts-add-button-scroll-top]');
var icon = '<span class="ts-icon ts-icon-arrow-down"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 9"><path fill="currentColor" d="M8.299 8.377l7.076-7.145a.43.43 0 000-.606.42.42 0 00-.6 0L8 7.466 1.226.626a.42.42 0 00-.6 0A.433.433 0 00.5.928c0 .108.04.219.126.301l7.076 7.144a.42.42 0 00.597.004z"></path></svg><span class="visually-hidden">Scroll hoch</span></span>';
var breakpoint = parseInt(getComputedStyle(document.body).getPropertyValue('--bs-breakpoint-xl'));
var setButtonVisibility = function (button) {
    if ((document.body.scrollTop > window.innerHeight
        || document.documentElement.scrollTop > window.innerHeight) && window.innerWidth > breakpoint) {
        button.classList.add('ts-button-scroll-top-show');
        return;
    }
    button.classList.remove('ts-button-scroll-top-show');
};
var backToTop = function () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};
var buttonScrollTop = function () {
    if (initializer.length === 0)
        return;
    var button = document.createElement('button');
    button.className = 'ts-button-scroll-top btn btn-primary';
    button.innerHTML = icon;
    button.addEventListener('click', backToTop);
    document.body.appendChild(button);
    setButtonVisibility(button);
    window.onscroll = function () { return setButtonVisibility(button); };
    window.onresize = function () { return setButtonVisibility(button); };
};
exports.default = buttonScrollTop;
