"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms = document.querySelectorAll('.ts-navbar-searchform');
var navBarSearch = function () {
    forms.forEach(function (form) {
        var input = form.querySelector('[name=ts-navbar-searchform-input]');
        form.addEventListener('submit', function (e) {
            var action = form.getAttribute('action');
            e.preventDefault();
            if (action) {
                window.location.href = "".concat(form.getAttribute('action'), "?text=").concat(input.value);
            }
        });
    });
};
exports.default = navBarSearch;
