/**
 * Animated lines on .ts-unique-selling-points
 */

 const inViewport = (entries) => {

  entries.forEach(entry => {
      entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
  });
};

const tsUniqueSellingPoints = () => {

  document.querySelectorAll(".ts-unique-selling-points-image-strokes .path").forEach((path) => {

    path.style.strokeDasharray = path.getTotalLength();
    path.style.strokeDashoffset = path.getTotalLength();

  });

  const Obs = new IntersectionObserver(inViewport);

  document.querySelectorAll('[data-inviewport]').forEach(el => {
      Obs.observe(el, {});
  });

};

export default tsUniqueSellingPoints;
