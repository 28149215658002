const $spinner = '<span class="ts-form-submit-spinner-icon"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>';

const buttonSpinner = ($button, active = true) => {

	if (active) {
		$button.classList.add('ts-form-submit-spinner-trigger');
		$button.disabled = true;
		$button.insertAdjacentHTML('beforeend', $spinner);

	} else {
		$button.classList.remove('ts-form-submit-spinner-trigger');
		$button.disabled = false;
		$button.querySelector('.ts-form-submit-spinner-icon').remove();
		
	}
			
};

export default buttonSpinner;
