import buttonSpinner from './buttonSpinner';

const formSubmitSpinner = (forms) => {

	forms.forEach((_forms) => {

		const $forms = document.querySelectorAll(_forms);

		if ($forms.length) {

			$forms.forEach(($form) => {

				const $trigger = $form.querySelector('button[type="submit"]') || document.querySelector(`button[type="submit"][form="${$form.id}"]`) || document.querySelector('main button[type="submit"]');
				$form.classList.add('ts-form-submit-spinner');
	
				$trigger && $form.addEventListener('submit', (e) => {
					buttonSpinner($trigger);
					//e.preventDefault();
				});

			});

		}
	});
};

export default formSubmitSpinner;
