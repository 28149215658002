"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var items = document.querySelectorAll('.ts-bookmark');
var bookmarks = function () {
    items.forEach(function (item) {
        item.addEventListener('click', function (e) {
            var url = item.getAttribute('data-href');
            var favoriteAddText = item.getAttribute('data-add') || '';
            var favoriteRemoveText = item.getAttribute('data-remove') || '';
            var medium = item.getAttribute('data-medium') || '';
            var spinner = item.querySelector('.ts-bookmark-spinner');
            var favoriteClass = 'is-bookmarked';
            spinner && spinner.classList.remove('d-none');
            var options = {
                url: url,
                params: {
                    medium: medium
                }
            };
            (0, axios_1.default)(options)
                .then(function (result) {
                spinner && spinner.classList.add('d-none');
                if (result.data.marked) {
                    item.classList.add(favoriteClass);
                    item.setAttribute('title', favoriteRemoveText);
                }
                else {
                    item.classList.remove(favoriteClass);
                    item.setAttribute('title', favoriteAddText);
                }
            })
                .catch(function (error) {
                if (error.response) {
                    //Todo: display error to user
                }
            });
            e.preventDefault();
        });
    });
};
exports.default = bookmarks;
