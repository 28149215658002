"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var throttle_1 = require("../lib/throttle");
var $initializers = document.querySelectorAll('[data-plugin=ts-sticky-list]');
var $navBar = document.querySelector('.ts-navbar');
var $stickySub = document.querySelector('[data-type=ts-sticky-nav-type-sub] > div');
var cssClassSticky = 'ts-sticky-list-item--is-sticky';
var cssClassPast = 'ts-sticky-list-item--is-past';
var cssClassBoy = 'ts-sticky-list-item--is-active';
var breakpoint = parseInt(getComputedStyle(document.body).getPropertyValue('--bs-breakpoint-sm'));
var makeSticky = function ($initializer) {
    var $items = $initializer.querySelectorAll('.ts-sticky-list-item');
    var navHeight = !!$navBar ? $navBar.offsetHeight : 0;
    var subHeight = !!$stickySub ? $stickySub.offsetHeight : 0;
    var top = navHeight + subHeight - 0.5; //-0.5 is hack to avoid flickering artefacts between sticky elements
    var result = [];
    $items.forEach(function (item) {
        var $item = item;
        $item.style.top = "".concat(top, "px");
        result.push($item.getBoundingClientRect().top === top);
    });
    var index = result.lastIndexOf(true);
    //add global class to the body if anything is sticky
    if (index > -1 && window.innerWidth < breakpoint) {
        document.body.classList.add(cssClassBoy);
    }
    else {
        document.body.classList.remove(cssClassBoy);
    }
    $items.forEach(function ($item, i) {
        //add sticky class to the active item
        if (i === index) {
            $item.classList.add(cssClassSticky);
        }
        else {
            $item.classList.remove(cssClassSticky);
        }
        //add past class to all items before the active item
        if (i < index) {
            $item.classList.add(cssClassPast);
        }
        else {
            $item.classList.remove(cssClassPast);
        }
    });
};
var stickyList = function () {
    $initializers.forEach(function ($initializer) {
        window.addEventListener('scroll', (0, throttle_1.default)(function () { return makeSticky($initializer); }, 200));
    });
};
exports.default = stickyList;
