"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var $initializers = document.querySelectorAll('[data-plugin=ts-swipe-horizontal]');
var $arrow = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 9"><path fill="currentColor" d="M8.299 8.377l7.076-7.145a.43.43 0 000-.606.42.42 0 00-.6 0L8 7.466 1.226.626a.42.42 0 00-.6 0A.433.433 0 00.5.928c0 .108.04.219.126.301l7.076 7.144a.42.42 0 00.597.004z"></path></svg>';
var $button = document.createElement('button');
$button.classList.add('ts-swipe-horizontal-button', 'btn', 'btn-link', 'p-0', 'd-flex', 'align-items-center' /*, 'position-absolute'*/);
var $icon = document.createElement('span');
$icon.classList.add('ts-icon', 'ts-icon-arrow-down');
$icon.innerHTML = $arrow;
var cssClassRight = 'ts-swipe-horizontal-button--right';
var cssClassLeft = 'ts-swipe-horizontal-button--left';
var steps = 200;
var swipeHorizontal = function () {
    $initializers.forEach(function ($initializer) {
        var $stage = $initializer.firstElementChild;
        var $buttonLeft = $button.cloneNode(true);
        $buttonLeft.classList.add(cssClassLeft, 'justify-content-start');
        $buttonLeft.appendChild($icon.cloneNode(true));
        var $buttonRight = $button.cloneNode(true);
        $buttonRight.classList.add(cssClassRight, 'justify-content-end');
        $buttonRight.appendChild($icon.cloneNode(true));
        $initializer.prepend($buttonLeft);
        $initializer.appendChild($buttonRight);
        var scrollStageTo = function (direction) {
            var pos = direction === 'right' ? steps : steps * -1;
            $stage.scrollTo({
                left: $stage.scrollLeft + pos,
                behavior: 'smooth'
            });
        };
        $buttonRight.addEventListener('touchstart', function () {
            scrollStageTo('right');
        });
        $buttonLeft.addEventListener('touchend', function () {
            scrollStageTo('left');
        });
        /*const checkButtonDisability = () => {
            const max = $stage.scrollWidth - $stage.clientWidth;
            ($stage.scrollLeft === 0) ? $buttonLeft.setAttribute('disabled', 'true') : $buttonLeft.removeAttribute('disabled');
            (Math.ceil($stage.scrollLeft) === max) ? $buttonRight.setAttribute('disabled', 'true') : $buttonRight.removeAttribute('disabled');
        };

        checkButtonDisability();

        
        $stage.addEventListener('touchend', function(event) {
            checkButtonDisability();
        });*/
    });
};
exports.default = swipeHorizontal;
