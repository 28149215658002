"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//core js file, used across _all_ pages
var formSubmitSpinner_js_1 = require("./components/formSubmitSpinner.js");
var stickyNav_1 = require("./components/stickyNav");
var cssVariables_js_1 = require("./components/cssVariables.js");
var swipeHorizonal_1 = require("./components/swipeHorizonal");
var bootstrap_1 = require("bootstrap");
var nerdEmoji = String.fromCodePoint(0x1f913) || '';
var isTouchDevice = function () { return window.matchMedia('(pointer: coarse)').matches; };
var resolveModule = function (modulePromise) {
    modulePromise.then(function (module) {
        if (typeof module.default === 'function') {
            module.default();
        }
    }); /*.catch((error) => {
        console.log('error', error);
        console.error(`Error loading`, error);
    })*/
};
document.addEventListener('DOMContentLoaded', function () {
    console.log("Hello Tagesspiegel Background ".concat(nerdEmoji));
    document.body.classList.add('js');
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.forEach(function (el) { return new bootstrap_1.Popover(el); });
    //general js
    (0, stickyNav_1.default)();
    (0, formSubmitSpinner_js_1.default)([
        'form#ts-searchform',
        'form#login-form',
        'form[name="tgs_user_registration_form"]',
        'form[name="tgs_user_profile_form"]',
        'form[name="tgs_user_change_password_form"]',
        'form.tgs_user_resetting_request',
        'form#jsUserRegistrationForm',
        'form.ts-navbar-searchform',
        'form[id^="subscription-"]',
    ]);
    (0, cssVariables_js_1.default)();
    //mobile only
    if (isTouchDevice()) {
        (0, swipeHorizonal_1.default)();
    }
    //hide mobile nav when clicking outside
    document.querySelectorAll('main, footer').forEach(function (el) {
        el.addEventListener('click', function () {
            var toggler = document.querySelector('.ts-navbar-toggler');
            if (!toggler)
                return;
            !toggler.classList.contains('collapsed') && toggler.click();
        });
    });
    //add target=blank to pressreview content
    document
        .querySelectorAll('.ts-pressreview a')
        .forEach(function (link) { return link.setAttribute('target', '_blank'); });
    //plugin-based js
    if (!!document.querySelector('[data-plugin=ts-bookmark]')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/bookmarks'); }));
    }
    if (!!document.querySelector('[data-plugin=ts-button-scroll-top]')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/buttonScrollTop'); }));
    }
    if (!!document.querySelector('[data-plugin=ts-date-selector]')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/dateRangePicker/dateRangePicker.js'); }));
    }
    if (!!document.querySelector('[data-plugin=ts-sticky-list]')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/stickyList'); }));
    }
    if (!!document.querySelector('[data-plugin=ts-unique-selling-points]')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/tsUniqueSellingPoints.js'); }));
    }
    if (!!document.querySelector('.ts-switch')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/tsSwitch.js'); }));
    }
    if (!!document.querySelector('.main form')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/validForm/validForm'); }));
    }
    if (!!document.querySelector('.user-offer')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/userOffer.js'); }));
    }
    if (!!document.getElementById('ts-load-more')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/loadMore'); }));
    }
    if (!!document.querySelector('.ts-text-toggler')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/textToggler'); }));
    }
    if (!!document.getElementById('stickySearch')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/stickySearch'); }));
    }
    if (!!document.querySelector('.ts-navbar-searchform')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/navBarSearch'); }));
    }
    if (!!document.querySelector('[data-plugin=ts-button-group-tick]')) {
        resolveModule(Promise.resolve().then(function () { return require('./components/buttonGroupTick'); }));
    }
});
