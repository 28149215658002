/**
 * Avoid toggling on .ts-switch elements when clicking on a label outside of the input field.
 */

const tsSwitch = () => {

  document.querySelectorAll(".ts-switch").forEach((tsSwitch) => {

    if (tsSwitch.querySelectorAll('label.ts-switch-outer').length < 2) return;
    
    const input = tsSwitch.querySelector('input');

    tsSwitch.querySelector('label.ts-switch-outer:first-of-type').addEventListener('click', e => !input.checked && e.preventDefault());
    tsSwitch.querySelector('label.ts-switch-outer:last-of-type').addEventListener('click', e => input.checked && e.preventDefault());

  });

};

export default tsSwitch;
