"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var buttonGroups = document.querySelectorAll('[data-plugin=ts-button-group-tick]');
var checkCheckedInputs = function (inputs) {
    var checkedInputs = Array.from(inputs).filter(function (input) { return input.checked; });
    if (checkedInputs.length === 1) {
        var label = checkedInputs[0].nextElementSibling;
        label.classList.add('disabled');
    }
    else {
        inputs.forEach(function (input) {
            var label = input.nextElementSibling;
            label.classList.remove('disabled');
        });
    }
};
var buttonGroupTick = function () {
    if (buttonGroups.length === 0)
        return;
    buttonGroups.forEach(function (buttonGroup) {
        var deselectable = (buttonGroup === null || buttonGroup === void 0 ? void 0 : buttonGroup.getAttribute('data-deselectable')) == '1';
        if (!deselectable) {
            var inputs_1 = buttonGroup.querySelectorAll('input');
            checkCheckedInputs(inputs_1);
            inputs_1.forEach(function (input) {
                input.addEventListener('change', function () {
                    checkCheckedInputs(inputs_1);
                });
            });
        }
    });
};
exports.default = buttonGroupTick;
